import { isFiniteNumber } from "@services/utils";
import moment from "moment";
import "moment-timezone";
import padStart from "lodash/padStart";
import { reportMessage } from "./logger";

export function getTimeFormatTemplate(date = true, seconds = true) {
  let format = "";
  if (date) {
    format = "YYYY-MM-DD ";
  }
  format += "HH:mm";
  if (seconds) {
    format += ":ss";
  }
  return format;
}

export function formatTime(
  time: number,
  timezone: string,
  date = true,
  seconds = true
) {
  const format = getTimeFormatTemplate(date, seconds);
  return moment(time).tz(timezone).format(format);
}

export function formatTimeForFilename(time: number, timezone: string) {
  return moment(time).tz(timezone).format("YYYY-MM-DD_HH-mm");
}

// eslint-disable-next-line no-var
export var timeOffset = 0; // FIXME
export const getNow = () => {
  return Date.now() + timeOffset;
};

export function setTimeOffset(offset: number) {
  timeOffset = offset;
}

function splitDelayIntoUnits(milliseconds: number): [number, number, number] {
  let seconds = Math.round(milliseconds / 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  seconds = Math.floor(seconds % 60);

  return [hours, minutes, seconds];
}

export function formatDelayHHMMSS(milliseconds: number) {
  const items = splitDelayIntoUnits(milliseconds);
  if (items[0] === 0) {
    items.splice(0, 1);
  }

  return items.map((i) => padStart(i.toString(), 2, "0")).join(":");
}

export function formatDelayHHMM(milliseconds: number) {
  const [hours, minutes] = splitDelayIntoUnits(milliseconds);
  const h = padStart(hours.toString(), 2, "0");
  const m = padStart(minutes.toString(), 2, "0");

  return `${h}:${m}`;
}

export function formatDelayHumanText(
  milliseconds: number,
  forceHours = false,
  forceMinutes = false
) {
  if (milliseconds < 0) {
    milliseconds = 0;
  }
  const units = splitDelayIntoUnits(milliseconds);
  const items = [];

  if (units[0] || forceHours) {
    items.push(padStart(units[0].toString(), 2, "0") + "h");
  }
  if (units[1] || forceMinutes) {
    items.push(padStart(units[1].toString(), 2, "0") + "m");
  }
  if (units[2] && !units[0] && !units[1]) {
    items.push(padStart(units[2].toString(), 2, "0") + "s");
  }
  if (!items.length) {
    items.push("0m");
  }
  return items.join(" ");
}

export function formatTimeDelayShort(relativeTs: number, absoluteTs: number) {
  let delay = Math.ceil(relativeTs / 1000) - Math.ceil(absoluteTs / 1000);
  if (delay >= 0) {
    return "0s";
  }

  let delayUnit;
  if (Math.abs(delay) < 300) {
    delayUnit = "s";
  } else if (Math.abs(delay) < 180 * 60) {
    delayUnit = "min";
    delay = Math.round(delay / 60);
  } else {
    delayUnit = "h";
    delay = Math.round(delay / 3600);
  }
  return delay + delayUnit;
}

export function toMilliSeconds(t: number): number {
  if (isFiniteNumber(t)) {
    return t * 1000;
  }

  return 0;
}

export function toMilliSecondsOrNull(t: number | null): number | null {
  if (isFiniteNumber(t)) {
    return toMilliSeconds(t);
  }

  return null;
}

const SLEEP_DELAY = 40000;
export function startSleepCheck() {
  let ts = Date.now();
  setInterval(() => {
    const current = Date.now();
    const delta = current - ts;
    if (delta > SLEEP_DELAY) {
      const extras = document.hidden
        ? "Tab is currently in the background"
        : "Tab is currently in the foreground";

      reportMessage(`Inactivity detected, reload page (${extras})`);

      // TODO: Re-initialize app without page reload
      window.location.reload();
    } else {
      ts = current;
    }
  }, 1000);
}

export function resetTime(ts: number, timezone?: string) {
  const dt = timezone ? moment(ts).tz(timezone) : moment(ts);

  return dt.startOf("day").toDate().getTime();
}

export function getDateMonthYear(ts: number, tz: string) {
  const dt = moment(ts).tz(tz);
  return [dt.date(), dt.month(), dt.year()];
}

export function floorToMinutes(ts: number) {
  return ts - (ts % 60000);
}
